import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const RowContainer = styled.div<{ selectedOffer?: boolean }>`
  background: ${(props) => props.theme.palette.background.bg1};
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.border.b1};
  padding: 18px 16px;
  display: flex;

  flex-direction: column;
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 12px;
    border: ${(props) =>
    props.selectedOffer
      ? '2px solid ' + props.theme.palette.primary.main
      : '1px solid ' + props.theme.palette.border.b1};
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
`;

export const RowLeft = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  @media (max-width: ${WIDTH.tabletMax}) {
    gap: 8px;
    height: fit-content;
  }
`;

export const SellerAvatarContainer = styled.div`
  @media (max-width: ${WIDTH.tabletMid}) {
    /* display: none; */
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 50%;
  > span {
    border-radius: 50%;
  }
`;

export const ImageBorder = styled.div<{ height?: string; width?: string }>`
  position: absolute;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.palette.text.t1};
  background: #00000000;
  height: ${(props) => (props.height ? props.height : '42px')};
  width: ${(props) => (props.height ? props.width : '42px')};
  opacity: 0.2;
  top: -1px;
  left: -1px;
`;

export const SellerDetailsContainer = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${WIDTH.tabletMax}) {
    width: 100%;
    margin-left: 0;
    flex-direction: row;
  }
`;

export const SellerRatingText = styled.p`
  font-family: Onest-Semibold;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.palette.text.t3};
  & > span {
    color: ${(props) => props.theme.palette.text.t1};
  }
`;

export const SellerNameContainer = styled.div`
  font: 0.875rem/1.125rem Onest-Bold;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${(props) => props.theme.palette.text.t1};
  flex: 1;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  gap: 2px;
  span {
    display: flex;
    gap: 4px;
    align-items: center;
    font-family: Onest-SemiBold;
    p {
      margin: 0;
      font: 0.75rem/1rem Onest-Medium;
    }
    &:hover {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }
`;

export const SellerHistoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: #14012d;
`;

export const SellerDot = styled.div`
  width: 2px;
  height: 4px;
  border-radius: 50%;
  margin: 0 2px;
  background: ${(props) =>
    props.theme.mode === 'dark' ? '#494949' : '#969696'};
`;

export const RatingContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 5px 0 0;
  font: 0.875rem/1.125rem Onest-Medium;
  gap: 4px;
  color: ${(props) => props.theme.palette.text.t2};
  & > span {
    font-family: Onest-Bold;
    color: ${(props) => props.theme.palette.text.t1};
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    gap: 2px;
  }
`;

export const RatingContainerOtherOffer = styled.div<{ ratingBgColor: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px 2px 6px;
  font: 0.875rem/1.125rem Onest-Medium;
  background-color: ${(props) => props.ratingBgColor};
  gap: 2px;
  border-radius: 12px;
  color: ${(props) => props.theme.palette.text.t1};
  & > p {
    font-family: Onest-SemiBold;
    color: ${(props) => props.theme.palette.white.main};
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    gap: 2px;
  }
`;

export const OrdersContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 5px;
  font-style: normal;
  font-family: Onest-Medium;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  @media (max-width: ${WIDTH.mobileMax}) {
    /* display: none; */
  }
  /* color: ${COLORS.greyDark}; */
`;

export const RowRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;

  @media (max-width: ${WIDTH.tabletMax}) {
    justify-content: space-between;
    width: 100%;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    align-items: center;
    gap: 16px;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: max-content;
  @media (max-width: ${WIDTH.tabletMax}) {
    align-items: unset;
    justify-content: unset;
  }
`;

interface PriceValueOthersI {
  characterLength: number;
}

export const PriceValueOthers = styled.div<PriceValueOthersI>`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 6px;
  font-family: Onest-SemiBold;
  font-size: ${(props) => (props.characterLength > 12 ? '.875rem' : '1.25rem')};
  line-height: ${(props) =>
    props.characterLength > 12 ? '1.125rem' : '1.5rem'};
  color: ${(props) => props.theme.palette.text.t1};
  // margin-right: 12px;
  @media (max-width: ${WIDTH.tabletMax}) {
    display: flex;
    gap: 4px;
    font-size: 18px;
  }
`;

export const DiscountAndMRP = styled.div`
  display: flex;
  gap: 8px;
  // margin-right: 12px;

  p {
    text-decoration: line-through;
    font: 0.75rem/1rem Onest-Medium;
    color: ${(props) => props.theme.palette.text.t3};
  }
  span {
    font: 0.75rem/1rem Onest-Medium;
    color: ${(props) => props.theme.palette.success.main};
  }
`;

export const ButtonContainer = styled.div`
  & > button {
    padding: 12px 16px;
    font: 0.875rem/1.125rem Onest-SemiBold;
  }
`;

export const MembershipButtonContainer = styled.div<{ membership: boolean }>`
  & > button {
    padding: 12px 16px;
    font: 0.875rem/1.125rem Onest-SemiBold;
    background: ${(props) =>
    props.membership
      ? `radial-gradient(
      100% 100% at 0% 0%,
      #b67efd 0%,
      #7626dcee 68.08%
    )`
      : props.theme.palette.button.primary};

    :hover {
      background: ${(props) =>
    props.membership
      ? `radial-gradient(
        100% 100% at 0% 0%,
        #b67efd 0%,
        #7626dc 68.08%
      )`
      : props.theme.palette.button.primary};
    }
  }
`;

export const DividerLineVertical = styled.div`
  width: 1px;
  height: 100%;
  background: ${(props) => props.theme.palette.border.b2};
`;

export const PriceName = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.greyDark};
`;

export const PriceValue = styled.div`
  font-family: Onest-Bold;
  font-size: 36px;
  line-height: 40px;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const MrpTagSec = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  font: 0.875rem/1.125rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t3};
  margin-left: 8px;
  @media (max-width: ${WIDTH.tabletMax}) {
    margin-left: 0;
  }
`;
export const MrpTag = styled.div<{ isLong?: number }>`
  display: flex;
  gap: 12px;
  align-items: center;

  font: ${(props) =>
    props.isLong && props.isLong >= 11 ? '.75rem/1rem' : '1rem/1.25rem'}
    Onest-Medium;
  color: ${(props) => props.theme.palette.text.t3};
  @media (max-width: ${WIDTH.mobileMax}) {
    font: ${(props) =>
    props.isLong && props.isLong >= 11 ? '.75rem/1rem' : '1rem/1.25rem'}
      Onest-Medium;
  }
`;

interface OptionalPara {
  position?: string;
}
export const DiscountTag = styled.div<OptionalPara>`
  background: #da0e3e;
  position: ${(props) => (props.position ? props.position : 'relative')};
  height: 24px;
  display: flex;
  padding: 0 8px 0 6px;
  border-radius: 1px 4px 4px 1px;
  align-items: center;
  &::before {
    content: '';
    position: absolute;
    width: ${24 / Math.SQRT2}px;
    height: ${24 / Math.SQRT2}px;
    border-radius: 0 0 0 4px;
    background: #da0e3e;
    z-index: 0;
    left: ${-12 / Math.SQRT2 + 0.5}px;
    transform: rotate(45deg);
  }
  > p {
    font: 0.875rem/1.125rem Onest-Bold;
    color: ${COLORS.white};
    z-index: 1;
  }
`;

export const CartButtonWrapper = styled.div`
  & button {
    background: #ececec;
    font-family: Onest-Bold;
    border: none;
    color: ${COLORS.black};
    &:hover {
      background: ${COLORS.grey};
    }
    &:active {
      background: ${COLORS.grey};
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;
  > button {
    padding: 12px 16px;
    font: 0.875rem/1.125rem Onest-SemiBold;
  }
`;

export const BuyNowButtonContainer = styled.div`
  & > button {
    background: #535ce8;
    &:hover {
      background: #535ce8;
      opacity: 0.9;
    }
    &:active {
      background: #535ce8;
      opacity: 0.9;
    }
  }
`;

export const SellerStatsContainer = styled.div`
  padding: 20px;
  background: ${(props) => props.theme.palette.background.bg4};
`;

export const SellerTitleContainer = styled.div`
  display: flex;
  gap: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${(props) => props.theme.palette.text.disabled};
`;

export const SellerHighRatingBanner = styled.div`
  padding: 16px;
  color: ${(props) => props.theme.palette.text.primary};
  background: ${(props) => props.theme.palette.primary.light};
  display: flex;
  gap: 8px;
  align-items: center;
  & > p {
    font-size: 14px;
    line-height: 16px;
    font-family: Onest-Regular;
    max-width: 316px;
  }
`;

export const SellerStats = styled.div`
  display: flex;
  gap: 32px;
  margin-top: 16px;
`;

export const SellerStatItem = styled.div`
  color: ${(props) => props.theme.palette.text.t1};
  & > h3 {
    font-size: 14px;
    line-height: 16px;
    font-family: Onest-Bold;
    margin: 0;
    margin-bottom: 4px;
  }
  & > p {
    font-size: 14px;
    line-height: 16px;
    font-family: Onest-Regular;
  }
`;

export const SellerRatingContainer = styled.div`
  display: flex;
  gap: 0px;
  align-items: center;
`;

export const RatingPill = styled.div`
  height: 24px;
  padding: 4px 8px;
  font-size: 16px;
  line-height: 16px;
  font-family: Onest-Bold;
  border-radius: 24px;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const NewStoreTag = styled.div`
  padding: 2px 4px;
  p {
    font-size: 10px;
    font-family: Onest-Semibold;
    line-height: 10px;
    color: ${(props) => '#019001'};
    margin: 1px 0 0;
  }
  border: 1px solid ${(props) => '#019001'};
  border-radius: 10px;
  height: 18px;
  min-width: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  @media (max-width: ${WIDTH.tabletMax}) {
    height: 18px;
  }
`;

export const RefundPolicyText = styled.div`
  font: 0.875rem/1.125rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t2};
  cursor: pointer;
`;

export const OffersStoreTag = styled.div<{
  colorTo?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  min-width: 18px;
  width: fit-content;
  padding: 2px;
  p {
    text-transform: uppercase;
    background: ${(props) =>
    props.colorTo
      ? props.colorTo
      : `linear-gradient(
          95.2deg,
          #cfa80b -12.04%,
          #c67a05 14.94%,
          #cfa80b 41.9%,
          #c67a05 68.89%,
          #cfa80b 95.83%
        )`};
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font: 0.625rem/0.625rem Onest-Bold;
    margin: 1px 0 0;
  }
  &::before {
    // border: 2px solid transparent;
    // border-image: linear-gradient(
    //     95.2deg,
    //     #cfa80b -12.04%,
    //     #c67a05 14.94%,
    //     #cfa80b 41.9%,
    //     #c67a05 68.89%,
    //     #cfa80b 95.83%
    //   )
    //   1;
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 4px;
    // border: 1px solid ${(props) => props.colorTo + '80'};
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
  }

  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 2px;
    height: 18px;
  }
`;

export const OffersStoreTagContainer = styled.div`
  border-radius: 4px;
  overflow: hidden;
  position: relative;
`;

export const DividerLine = styled.div`
  height: 1px;
  width: 100%;
  background: ${(props) => props.theme.palette.border.b1};
  margin: 12px 0;
`;
